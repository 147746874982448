window.Vue = require('vue');
import Vue from "vue";
import SearchHome from './SearchHome.vue';
import locale from 'element-ui/lib/locale/lang/es';
import Element from 'element-ui';
import VueSweetalert2 from 'vue-sweetalert2';
import 'element-ui/lib/theme-chalk/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.use(Element, { size: 'large', locale});
Vue.use(VueSweetalert2);
window.EventBus = new Vue({});
let token = document.querySelector('#token').dataset.token;
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
new Vue ({
    el: '#app',
    components:{
        'search-home':SearchHome
    }

});
