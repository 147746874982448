<template>

    <div>
        <template v-if="this.search === 1">
            <h4 class="subtitle-home">{{total_information[1]}} profesionales registrados</h4>
            <div class="row container-search justify-content-center mx-auto">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 form-group">
                    <el-select v-model="search" :placeholder="$options.filters.translate('vue.textos_show_all')">
                        <el-option
                                v-for="(item, index) in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="col-xl-5 col-lg-4 col-md-4 col-sm-6 form-group">
                    <vue-google-autocomplete
                            ref="address2"
                            id="map"
                            classname="el-input__inner"
                            :placeholder="$options.filters.translate('vue.textos_ciudad')"
                            v-model="route.address"
                            v-on:placechanged="getAddressData"
                            types="geocode"
                            v-on:no-results-found="noResultsFound"
                    >
                    </vue-google-autocomplete>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 form-group">
                    <el-button class="btn-block btn-lg" type="primary" @click="searchCoach()">
                        <i class="fas fa-search"></i>
                        {{trans('vue.textos_buscar')}}
                    </el-button>
                </div>
            </div>

        </template>

        <template v-if="this.search === 2">
            <h4  v-if="this.mostrarCursos" class="subtitle-home">{{total_information[2]}} cursos</h4>
            <div class="row justify-content-center container-search mx-auto">

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 form-group">
                    <el-select v-model="search" :placeholder="$options.filters.translate('vue.textos_show_all')">
                        <el-option
                                v-for="(item, index) in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 form-group">
                    <el-input
                            :placeholder="$options.filters.translate('vue.textos_ciudad')"
                            v-model="courses.city"
                            clearable
                            @keyup.enter.native="searchCourses()"

                    >
                    </el-input>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 form-group">
                    <el-select v-model="courses.modality" clearable filterable
                               :placeholder="$options.filters.translate('vue.courses_modality')">
                        <el-option
                                v-for="(item, index) in this.modalities"
                                :key="index"
                                :label="item"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 form-group">
                    <el-button class="btn-block btn-lg" type="primary" @click="searchCourses()">
                        <i class="fas fa-search"></i>
                        {{trans('vue.textos_buscar')}}
                    </el-button>
                </div>
            </div>

        </template>

        <template v-if="this.search === 3">
            <h4 class="subtitle-home">{{total_information[3]}} compañias registradas</h4>
            <div class="row container-search justify-content-center justify-content-lg-start mx-auto">

                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 form-group">
                    <el-select v-model="search" :placeholder="$options.filters.translate('vue.textos_show_all')">
                        <el-option
                                v-for="(item, index) in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 form-group">
                    <el-select v-model="companies.country" clearable filterable
                               :placeholder="$options.filters.translate('vue.textos_pais')">
                        <el-option
                                v-for="(item, index) in companies.countries.countries"
                                :key="index"
                                :label="item.nameen"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 form-group">
                    <el-select v-model="companies.region" clearable filterable
                               :placeholder="$options.filters.translate('vue.textos_region')">
                        <el-option
                                v-for="(item, index) in companies.regions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 form-group">
                    <el-input
                            @keyup.enter.native="searchCompanies()"
                            :placeholder="$options.filters.translate('vue.textos_ciudad')"
                            v-model="companies.city"
                            clearable
                    >
                    </el-input>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 form-group">
                    <el-button class="btn-block btn-lg" type="primary" @click="searchCompanies()">
                        <i class="fas fa-search"></i>
                        {{trans('vue.textos_buscar')}}
                    </el-button>
                </div>
            </div>

        </template>

        <template v-if="this.search === 4">
            <h4 class="subtitle-home">{{total_information[4]}} clases</h4>
            <div class="row container-search mx-auto">

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 form-group">
                    <el-select v-model="search" :placeholder="$options.filters.translate('vue.textos_show_all')">
                        <el-option
                                v-for="(item, index) in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 form-group">
                    <vue-google-autocomplete
                            ref="address2"
                            id="map"
                            classname="el-input__inner"
                            :placeholder="$options.filters.translate('vue.textos_ciudad')"
                            v-model="route.address"
                            v-on:placechanged="getAddressData"
                            types="geocode"
                            v-on:no-results-found="noResultsFound"
                    >
                    </vue-google-autocomplete>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 form-group">
                    <el-select v-model="clases.range_id"
                               :placeholder="$options.filters.translate('vue.textos_clases-horarios')">
                        <el-option
                                v-for="(item, index) in range_hours"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 form-group">
                    <el-button class="btn-block btn-lg" type="primary" @click="searchClass()">
                        <i class="fas fa-search"></i>
                        {{trans('vue.textos_buscar')}}
                    </el-button>
                </div>
            </div>

        </template>


    </div>
</template>
<script>

    import VueGoogleAutocomplete from 'vue-google-autocomplete'

    export default {
        name: "SearchHome",
        components: {
            'vue-google-autocomplete': VueGoogleAutocomplete
        },
        props: ['skills_coachs', 'skills_courses', 'modalities'],
        data() {
            return {
                name: '',
                total_information: [],
                route: {
                    latitude: '',
                    longitude: '',
                    address: '',
                    skill: '',
                },
                search: '',

                courses: {
                    city: '',
                    modality: '',
                    skill: '',
                },
                clases: {
                    city: '',
                    skill_id: '',
                    time_start: '00:00',
                    time_finish: '23:59',
                    range_id: ''
                },

                companies: {
                    name: '',
                    regions: '',
                    region: '',
                    countries: '',
                    country: '',
                    city: '',
                },

                options: [
                    {
                        value: 1,
                        label: this.trans('vue.header_aprofessional')
                    },
                    {
                        value: 2,
                        label: this.trans('vue.header_acourse')
                    },
                    {
                        value: 3,
                        label: this.trans('vue.textos_empresa')
                    },
                    {
                        value: 4,
                        label: this.trans('vue.header_aclass')
                    }],

                range_hours: [
                    {
                        value: 1,
                        label: '6:00-12:00'
                    },
                    {
                        value: 2,
                        label: '12:00-18:00'
                    },
                    {
                        value: 3,
                        label: '18:00-23:00'
                    }],

                    mostrarCursos:false
            }
        },

        created() {
            this.search = 1;
            this.getCountries();
            this.getTotalOfAll();

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(this.geolatlong)
            } else {
            /* la geolocalización NO está disponible */
            }
        },
        watch: {
            'companies.country': function () {
                this.getRegions();
            },
            'clases.range_id': function () {
                switch (this.clases.range_id) {
                    case 1:
                        this.clases.time_start = '06:00';
                        this.clases.time_finish = '12:00';
                        break;
                    case 2:
                        this.clases.time_start = '12:00';
                        this.clases.time_finish = '18:00';
                        break;
                    case 3:
                        this.clases.time_start = '18:00';
                        this.clases.time_finish = '23:00';
                        break;
                    default:
                        this.clases.time_start = '00:00';
                        this.clases.time_finish = '23:59';
                        break
                }
            }
        },
        methods: {
            getAddressData: function (addressData, placeResultData, id) {
                this.route.address = addressData.locality;
                this.route.longitude = addressData.longitude;
                this.route.latitude = addressData.latitude;
            },
            noResultsFound() {
                this.$notify.error({
                    title: 'Error Select a location',
                });
            },
            searchCoach() {
                window.location = '/lista-entrenadores?latitude=' + this.route.latitude + '&longitude=' + this.route.longitude + '&name=' + this.name + '&address=' + this.route.address + '&skill_id=' + this.route.skill;
            },
            searchCourses() {
                window.location = '/cursos?city=' + this.courses.city + '&modality=' + this.courses.modality + '&skill=' + this.courses.skill;
            },

            getRegions() {
                axios.get(`/getRegions/offer/${this.companies.country}`)
                    .then(res => {
                        this.companies.region = '';
                        this.companies.regions = res.data;
                    })
                    .catch(e => {
                        console.log(e.response);
                    })
            },
            getTotalOfAll() {
                axios.get(`/get-total-information`)
                    .then(res => {
                        this.total_information = res.data;
                        this.mostrarCursos = !(this.total_information[2] < 10);
                    })
                    .catch(e => {
                        console.log(e.response);
                    })
            },
            getCountries() {
                axios.get(`/getCountries`)
                    .then(res => {
                        this.companies.countries = res.data;
                    })
                    .catch(e => {
                        console.log(e.response);
                    })
            },
            searchCompanies() {

                window.location = '/empresas-asociadas?city=' + this.companies.city + '&country_id=' + this.companies.country + '&region_id=' + this.companies.region + '&name=' + this.companies.name;
            },

            searchClass() {
                window.location = '/class?latitude=' + this.route.latitude + '&longitude=' + this.route.longitude + '&address=' + this.route.address + '&skill_id=' + this.clases.skill_id + '&time_start=' + this.clases.time_start + '&time_finish=' + this.clases.time_finish;
            },
            trans(value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            },
            setAddress(value) {
                if (!value) return '';
                this.route.address = value;
            },
            geolatlong(position) {
                this.route.latitude = position.coords.latitude;
                this.route.longitude = position.coords.longitude;

                const geocoder = new google.maps.Geocoder

                const text = document.getElementById('map')

                let setLocalAddress  = this.setAddress;

                geocoder.geocode({
                    'location': {
                        lat: this.route.latitude,
                        lng: this.route.longitude
                    }
                }, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            text.value = results[0]['address_components'][2]['long_name'];
                            setLocalAddress(text.value);
                        }
                    }
                })


            },
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    }
</script>
